//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { assetURL } from '@/plugins/utilities'
export default {
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slider: 'ladies',
      products: {
        ladies: [
          {
            title: this.$t('menu.ladies.shirts'),
            media: {
              alternate: true,
              cf_path: '/SnBlf8K8GOHNVwwP-ladies-typeslider-image-1',
            },
            link: this.$t('header.mainMenu.ladies.subMenus.clothing.items')[2]
              .to,
          },
          {
            title: this.$t('menu.ladies.polos'),
            media: {
              alternate: true,
              cf_path: '/AvLYkHq3T5uqw8O4-ladies-typeslider-image-2',
            },
            link: this.$t('header.mainMenu.ladies.subMenus.clothing.items')[3]
              .to,
          },
          {
            title: this.$t('menu.ladies.knitwearJumpers'),
            media: {
              alternate: true,
              cf_path: '/XbETMRDerhBxndN0-ladies-typeslider-image-3',
            },
            link: this.$t('header.mainMenu.ladies.subMenus.clothing.items')[7]
              .to,
          },
          {
            title: this.$t('menu.ladies.jacketsCoats'),
            media: {
              alternate: true,
              cf_path: '/BuFiHZFF1BXPa40q-ladies-typeslider-image-4',
            },
            link: this.$t('header.mainMenu.ladies.subMenus.clothing.items')[0]
              .to,
          },
          {
            title: this.$t('menu.ladies.footwear'),
            media: {
              alternate: true,
              cf_path: '/oW7345M2Oh9lrymg-ladies-typeslider-image-5',
            },
            link: this.$t('header.mainMenu.ladies.subMenus.footwear.to'),
          },
        ],
        men: [
          {
            title: this.$t('menu.mens.shirts'),
            media: {
              alternate: true,
              cf_path: '/UINjJG9o04924LC0-mens-typeslider-image-1',
            },
            link: this.$t('header.mainMenu.mens.subMenus.clothing.items')[2].to,
          },
          {
            title: this.$t('menu.mens.polos'),
            media: {
              alternate: true,
              cf_path: '/nHkqoSrf6vkP94vZ-mens-typeslider-image-2',
            },
            link: this.$t('header.mainMenu.mens.subMenus.clothing.items')[3].to,
          },
          {
            title: this.$t('menu.mens.knitwearJumpers'),
            media: {
              alternate: true,
              cf_path: '/m1mBTnLZXnZ6RyNz-mens-typeslider-image-3',
            },
            link: this.$t('header.mainMenu.mens.subMenus.clothing.items')[8].to,
          },
          {
            title: this.$t('menu.mens.jacketsCoats'),
            media: {
              alternate: true,
              cf_path: '/SLaGR4FDFg2kjpKl-mens-typeslider-image-4',
            },
            link: this.$t('header.mainMenu.mens.subMenus.clothing.items')[0].to,
          },
          {
            title: this.$t('menu.mens.footwear'),
            media: {
              alternate: true,
              cf_path: '/OEt0JddW6nTrDi2A-mens-typeslider-image-5',
            },
            link: this.$t('header.mainMenu.mens.subMenus.footwear.to'),
          },
        ],
        kids: [
          {
            title: this.$t('menu.children.shirtsAndPolos'),
            media: {
              alternate: true,
              cf_path: '/eqBQrMOF0IGIss8m-kids-typeslider-image-1',
            },
            link: '/collections/childrens-shirts-polos',
          },
          {
            title: this.$t('menu.children.knitwear'),
            media: {
              alternate: true,
              cf_path: '/X4dFhwqreOGwXMD9-kids-typeslider-image-2',
            },
            link: '/collections/childrens-knitwear-jumpers',
          },
          {
            title: this.$t('menu.ladies.jacketsCoats'),
            media: {
              alternate: true,
              cf_path: '/ANc3wCiRGUfQ5Yyf-kids-typeslider-image-3',
            },
            link: '/collections/childrens-jackets-fleeces',
          },
          {
            title: this.$t('menu.children.footwear'),
            media: {
              alternate: true,
              cf_path: '/jidIGdXwn6DN0GSi-kids-typeslider-image-4',
            },
            link: '/collections/childrens-footwear',
          },
          {
            title: this.$t('menu.children.babyClothing'),
            media: {
              alternate: true,
              cf_path: '/ogSKX7jQvOSWxN6V-kids-typeslider-image-5',
            },
            link: '/collections/baby-clothing',
          },
        ],
      },
    }
  },
  methods: {
    assetURL,
    scrollTo(index) {
      const clientWidth = this.$refs.scrollDiv.clientWidth
      this.activeIndex = index

      this.$refs.scrollDiv.scrollTo({
        left: index * clientWidth,
        top: 0,
        behavior: 'smooth',
      })
    },
    watchScroll(e) {
      const clientWidth = this.$refs.scrollDiv.clientWidth
      const scrollLeft = this.$refs.scrollDiv.scrollLeft
      this.activeIndex = Math.ceil(scrollLeft / clientWidth)
    },
  },
}
